import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/pages/privacy-policy.styles';

const Div = styled.div`
  padding: 40px 20px;

  ol {
    li {
      margin-bottom: 20px;

      ol {
        margin-top: 20px;
        li {
          list-style-type: lower-alpha;
        }
      }
    }
  }

  ul {
    li {
      margin-bottom: 5px;
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const CaliforniaSupplyChainTransparencyLaw = ({ data }) => {
  const { content } = data.wordpressPage;
  return (
    <Div>
      <Helmet>
        <title>{data.wordpressPage.yoast_head_json.title}</title>
        <meta
          name="description"
          content={data.wordpressPage.yoast_head_json.description}
        />
      </Helmet>
      {ReactHtmlParser(content)}
    </Div>
  );
};

export default CaliforniaSupplyChainTransparencyLaw;

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "california-supply-chain-transparency-law" }) {
      yoast_head_json {
        title
        description
      }
      content
    }
  }
`;
